@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.upload {
  @include desktop {
    margin: 0rem;
    padding: 0rem;
  }

  &-section {
    margin: 2rem;
  }

  &-section__container {
    @include desktop {
      display: flex;
      flex-direction: row;
    }
  }
  &-image__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
      width: 45%;
      display: flex;
      flex-direction: column;
      margin-right: 3rem;
    }
  }

  &-form {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include desktop {
      display: flex;
      width: 50%;
      margin-top: 0rem;
      padding-top: 0rem;
    }
  }

  &-input {
    font-weight: 400;
    font-family: Poppins;
    border-radius: 0.5rem;
    border: solid 2px $upload-border;
    padding-top: 1rem;
    resize: none;
    padding-left: 1rem;

    margin: 1rem;
  }

  &-input:focus {
    outline: 2px solid $primary-cta-color;
    border: none;
  }

  &-button {
    border: none;
    color: $background-cta-color;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    width: 100%;
    height: 3rem;
    border-radius: 0.3rem;
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: 5%;

    @include tablet {
      min-width: 8rem;
      max-width: 15rem;
      margin-left: 4rem;
    }

    @include desktop {
      min-width: 8rem;
      max-width: 15rem;
      margin-left: 4rem;
    }

    &__button--icon {
      display: flex;
      width: 50%;
    }
  }

  &-button__cancel {
    color: $secondary-text-color;
    font-weight: 700;
    text-align: center;
    padding: 2rem;
    font-size: 1rem;
  }

  &-button__container {
    @include tablet {
      margin-top: 3rem;
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      justify-content: flex-start;
      margin-right: 2.5rem;
      align-items: baseline;
    }
  }

  &-image__preview {
    width: 100%;
    max-height: 30rem;
    max-width: 26rem;
    border-radius: 8%;
    background-image: url("../../../assets/Icons/image.svg");
    height: 100%;
    margin-bottom: 1rem;

    @include tablet {
      max-height: 28rem;
      max-width: 25rem;
    }
  }
  &-button__icon {
    width: 2.8rem;
  }

  &-image__container input[type="file"] {
    width: 100%;
    height: 100%;
    color: $upload-color;
    font-family: Poppins;
    cursor: pointer;
  }

  &-file__label {
    background-color: $upload-file;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  &-file__label:hover {
    background-color: $primary-cta-color;
    color: $primary-text-color;
  }

  &-file__input {
    display: none;
  }

  &-button:hover {
    background-color: $primary-cta-color;
  }
}

a {
  text-decoration: none;
}
