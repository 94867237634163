@use "./styles/partials/mixins" as *;
@use "./styles/partials/typography" as *;
@use "./styles/partials/variables" as *;
// boxsizing

*,
*::before,
*::after {
  box-sizing: border-box;
}

// body styling

.App {
  font-family: "Poppins", Times, serif;
}
