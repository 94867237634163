@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.hero {
  margin: 0rem;

  &-works {
    border: solid 1px rgb(215, 214, 214);
    box-shadow: 5px 5px 5px rgb(210, 210, 210);
    border-radius: 1rem;
    height: rem;
    margin: 1rem;
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    transition: transform 0.2s;
    cursor: pointer;

    @include tablet {
      width: 19rem;
      height: 16rem;
    }
  }

  &-works:hover {
    transform: scale(1.1);
  }
  &-subheader {
    text-align: center;
    font-size: 1rem;
  }

  &-icon {
    width: 3rem;
    max-height: 3rem;
    margin: 1rem;
  }

  &-button__container {
    display: flex;
    justify-content: space-around;
    margin: 2rem;
  }

  &-button__text {
    width: 9rem;

    text-align: center;
    color: $primary-text-color;
  }

  &-text__container {
    display: flex;

    @include tablet {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &-container {
    margin-top: 4rem;

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}
