@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-Thin.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-ExtraLight.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-Light.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-Regular.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-Medium.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-ExtraLight.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-Bold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-ExtraBold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-ExtraBold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: Poppins;
  src: url(../../assets/Font/Poppins/Poppins-Black.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 900;
}
