@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/typography" as *;
@use "../../../styles/partials/variables" as *;

.single-listing {
  padding-bottom: 5rem;

  &__button {
    background-color: $primary-background-color;
    border-radius: 5rem;
    border: none;
  }

  &-icon {
    width: 3rem;
    cursor: pointer;
  }

  &__container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 4rem;
  }
}
