@use "./variables" as *;

// media queries

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}
