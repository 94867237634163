@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: $background-footer;
  height: 3.5rem;
  position: fixed;
  bottom: 0rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding-top: 0.3rem;

  &-icon {
    width: 3rem;
    padding: 0.3rem;
  }

  &-icon:hover {
    width: 3.2rem;
    padding-bottom: 1rem;
  }
}
