@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.item-info {
  margin: 2.25rem;

  @include tablet {
    margin: 4.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  @include desktop {
    margin: 0rem;
    padding-left: 7rem;
    padding-top: 2rem;
  }

  &__header {
    font-size: 2rem;
    font-weight: 400;
    margin: 1rem;
    text-align: center;

    @include tablet {
      border: none;
      font-size: 2rem;
    }
  }

  &__description {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5rem;
    margin-left: 1rem;
  }

  &__container {
    display: flex;
    justify-content: center;

    @include tablet {
      display: flex;
      justify-content: space-between;
    }
  }
  &__location {
    padding: 0rem 1rem;
    font-weight: 400;
  }

  &__item {
    padding: 0rem 1rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  &__map {
    width: 100%;
    height: 25rem;
    margin-top: 1rem;
    border-radius: 2rem;

    @include tablet {
      margin-top: 10rem;
    }

    @include desktop {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 40rem;
      width: 40rem;
      margin-right: 4rem;
      margin-top: 1rem;
    }
  }

  &__icon {
    width: 3rem;
    cursor: pointer;

    margin-top: 4rem;
  }

  &__iconcontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__tablet {
    @include tablet {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-right: 7rem;
    }
  }
}

.single-item {
  display: flex;
  margin: 0rem;

  padding-top: 1rem;
  justify-content: center;

  &__image {
    max-width: 20rem;
    height: 20rem;
    border-radius: 10%;
    width: 100%;
    margin: 0;
    z-index: 1;
    max-height: 40rem;
    padding: 0rem, 56.25%;
  }
}
