@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.navbar {
  align-items: center;
  margin: 0rem, 1rem;
  background-image: url("../../assets/Images/matt-hanns-schroeter-YJuyTFWPjZU-unsplash\ \(1\).jpg");
  background-size: cover;
  background-position: center;
  height: 15rem;
  width: 100%;

  @include tablet {
    background-size: cover;
    background-position: center;
    height: 22rem;
    width: 100%;
  }

  @include desktop {
    background-size: cover;
    background-position: center;
    height: 22rem;
    width: 100%;
  }

  &-container {
    display: flex;
    padding: 1rem;
  }

  &-box {
    margin-left: 4rem;
    margin-top: 5rem;

    @include tablet {
      margin-left: 20rem;
    }

    @include desktop {
      margin-left: 45rem;
    }
  }
}

.logo {
  width: 15rem;

  @include tablet {
    width: 30rem;
  }
}
