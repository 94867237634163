@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.aside {
  @include tablet {
    width: 31rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    z-index: 0;
    justify-content: center;

    @include tablet {
      margin: 0rem;
      width: 100%;
      justify-content: space-evenly;
      flex-direction: row;
    }

    @include desktop {
      margin: 0rem;
      width: 100%;
      justify-content: space-evenly;
      flex-direction: row;
    }
  }

  &__image {
    cursor: pointer;
    width: 20rem;
    height: 20rem;
    border-radius: 10%;
  }

  &__box {
    margin: 4rem;
    padding: 1rem;
    height: 33rem;

    border: 1px solid $gallery-border;
    border-radius: 0.7rem;
    max-width: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 5px 5px 5px $gallery-boxshadow;
    transition: transform 0.2s;
  }

  &__box:hover {
    transform: scale(1.1);
  }

  &__title {
    margin-left: 1.5rem;
    margin-bottom: 3rem;
    font-size: 1rem;
    color: $secondary-text-color;
  }

  &__likes {
    display: flex;
    color: $secondary-text-color;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__description {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__location {
    padding: 0;
    margin: 0;
    color: $secondary-text-color;
    margin-left: 1.5rem;
  }

  &__heart {
    width: 1rem;
  }

  &__map {
    width: 100%;
    height: 5rem;
  }

  &__button {
    background-color: $primary-background-color;
    border-radius: 10px;
    font-family: Poppins;
    font-weight: 600;
    height: 2rem;
  }

  &__text {
    margin-top: 1.9rem;
    width: 23rem;
  }

  &__link {
    display: flex;
    justify-content: center;
  }

  &__image.claimed {
    filter: grayscale(100%);
  }

  &__button.claimed {
    background-color: $secondary-text-color;
    color: white;
  }
}
