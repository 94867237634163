@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

// carousel styling

.carousel-cell img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}

.carousel-cell {
  width: 100%;
  background: $secondary-text-color;
}

.carousel-cell {
  width: 80%;
  height: 100vh;
  margin-right: 10px;
  background: $primary-background-color;
  border-radius: 5px;
  overflow: hidden;
}

.carousel-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-cell {
  width: 100%;
}

@media only screen and (max-width: 200px) {
  .carousel {
    max-width: 900px;
  }

  .carousel-image {
    max-width: 100%;
    max-height: 300px;
  }
}

// homepage copy styling

.homepage-copy {
  position: absolute;
  top: 0rem;
  color: white;
  left: 21px;

  @include tablet {
    text-align: left;
  }
}

.homepage-copy__one {
  font-size: 1rem;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  position: absolute;
  top: 18rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  left: 46px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 7px;
  padding: 1rem;
  max-width: 18rem;
  transition: transform 250ms;

  @include tablet {
    font-size: 1.5rem;
    max-width: 48rem;
    left: 30rem;
    top: 2rem;
  }
  @include desktop {
    font-size: 1.5rem;
    max-width: 48rem;
    left: 0rem;
    top: 2rem;
  }
}

.homepage-copy__one:hover {
  transform: translateY(-10px);
}

.homepage-copy__two {
  font-size: 1rem;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  position: absolute;
  top: 15rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  left: 46px;
  background-color: rgba(0, 0, 0, 0.7);

  border-radius: 7px;
  padding: 1rem;
  max-width: 18rem;
  transition: transform 250ms;

  @include tablet {
    font-size: 1.5rem;
    max-width: 48rem;
  }
}

.homepage-copy__two:hover {
  transform: translateY(-10px);
}

.carousel-cell {
  position: relative;
}

.flickity-viewport.flickity-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

// logo styling

.logo-container {
  margin-top: 6rem;
  margin-left: 0rem;
  width: 20rem;

  @include tablet {
    margin-top: 6rem;
    margin-left: 16rem;
    width: 26rem;
  }

  @include desktop {
    margin-top: 6rem;
    margin-left: 36rem;
    width: 36rem;
  }
}

.logos {
  max-width: 100%;
  height: auto;
  margin-top: 3rem;
  margin-left: 2rem;
}

// header styling

.header {
  align-items: center;
  margin: 0rem, 1rem;
  background-size: cover;
  background-position: center;
  height: 53rem;
  width: 100%;
  min-width: 25rem;

  @include tablet {
    background-size: cover;
    background-position: center;
    height: 40rem;
    width: 100%;
  }

  @include desktop {
    background-size: cover;
    background-position: center;
    height: 42rem;
    width: 100%;
  }

  &-button {
    color: $secondary-text-color;
    font-weight: 700;
    background-color: $button-background-color;
    width: 10rem;
    text-align: center;
    font-size: 0.6rem;
    border-radius: 10px;
    padding: 0.4rem;
    cursor: pointer;
    transition: all 0.3s;

    @include tablet {
      font-size: 1rem;
    }
    &:hover {
      background-color: $primary-background-color;
      color: $secondary-text-color;
    }
  }

  &-container {
    display: flex;
  }

  &-logo {
    width: 10.3rem;
    position: absolute;
    top: 5.5rem;
    left: 2.12rem;
    z-index: 0;

    @include tablet {
      top: 4rem;
      left: 22.6rem;
    }
    @include desktop {
      top: 6.2rem;
      left: 33rem;
      width: 10.3rem;
    }
  }

  &-title {
    z-index: 1;
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    font-size: 6rem;
    color: $primary-text-color;
    font-weight: 400;

    @include tablet {
      left: 25rem;
      top: 0rem;
    }

    @include desktop {
      left: 35rem;
      top: 0rem;
      font-size: 8rem;
    }
  }

  &-copy__container {
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
    margin-top: 3rem;
    @include tablet {
      padding-left: 6rem;
      margin-left: 2rem;
    }
  }

  &-copy {
    color: $primary-text-color;
    width: 15rem;
    font-size: 0.9rem;
    font-weight: 500;

    @include tablet {
      font-size: 1.1rem;
      width: 25rem;
      margin-top: 3rem;
    }

    @include desktop {
      font-size: 1.8rem;
      width: 45rem;
    }
  }

  &-subheader {
    color: $primary-text-color;
    font-size: 1.6rem;

    @include tablet {
      font-size: 2.5rem;
    }

    @include desktop {
      font-size: 3.5rem;
    }
  }

  &-logo__container {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  &-logo__image {
    max-width: 100%;
    height: auto;
    padding: 15%;
  }
}
